<script setup>

import LeaderBoardSIdebar from "~/components/Campaign/LeaderBoardSIdebar.vue";

const props = defineProps({
    members: Array,
    campaignName: String,
    creatorNumber: {
        type: Number,
        default: 10
    },
    showTitle: {
        type: Boolean,
        default: true
    },
    showFooter: {
        type: Boolean,
        default: true
    },
    layout: {
        type: String,
        default: 'default'
    },
    showShares: {
        type: Boolean,
        default: true
    }
});

const creatorNumber = computed(() => {
    return props.creatorNumber;
});

const layout = computed(() => {
    return props.layout;
});

const members = computed(() => {

    return props.members.map((member, index) => {

        let rowClass = '';

        if (member?.isLoggedUser) {
            rowClass = 'bg-black text-white';


        } else if (index == 0) {
            rowClass = 'bg-purple-light';

            if (layout.value == "paycheck") {
                rowClass = 'bg-white text-black';
            }

        } else if (index == 1) {
            rowClass = 'bg-grey-light';

            if (layout.value == "paycheck") {
                rowClass = 'bg-black/20 text-black';
            }

        }

        return {
            ...member,
            rowClass,
        }
    });

});

const leaderboardSidebarVisible = ref(false);

const getTopMembersWithYou = computed(() => {
    // Get the first 5 members

    let topMembers = removeReactivity(members.value).slice(0, creatorNumber.value);

    // Find the index of "you" in the original array
    const currentLoggedUserIndex = members.value.findIndex(member => member?.isLoggedUser);

    if (currentLoggedUserIndex >= creatorNumber.value) {

        let restNum = creatorNumber.value - 1;
        topMembers = topMembers = removeReactivity(members.value).slice(0, restNum);

        let currentLoggedUserMember = removeReactivity(members.value[currentLoggedUserIndex]);

        // Append "you" as the 6th member with the correct place number
        topMembers.push({
            ...currentLoggedUserMember,
            rank: currentLoggedUserIndex + 1 // The place number is your original position + 1
        });
    }

    return topMembers;
})

const hiddenMembersCount = computed(() => {
    let rest = members.value.length - creatorNumber.value;

    if (rest <= 0)
        return 0;

    return rest;
})

</script>

<template>

    <div v-if="!members.length" class="flex items-center justify-center h-full">

    </div>
    <div v-else>
        <Sidebar v-if="leaderboardSidebarVisible" @close="leaderboardSidebarVisible = false">
            <LeaderBoardSIdebar :members="members" :campaign-name="campaignName"/>
        </Sidebar>

        <div v-if="showTitle" class="text-lg mb-6">{{ members.length }} eligible creators</div>

        <div class="flex flex-col gap-1 font-semibold">
            <div v-for="(member, memberIndex) in getTopMembersWithYou" class="flex items-center justify-between p-1.5 px-3 rounded-lg" :class="member.rowClass">
                <div class="flex items-center gap-4">
                    <span class="w-4 text-right">{{ member.rank }}</span>
                    <img :src="member.userProfilePictureUrl" class="w-6 aspect-square rounded-full" alt="">
                    <div class="max-w-28 lg:max-w-20 truncate lg:max-w-none">@{{ member.userTwitterHandle }}</div>
                </div>
                <div v-if="showShares" class="flex items-center gap-2">
                    <div v-if="memberIndex == 0" class="text-black/50 hidden lg:block">Impact Share</div>
                    <div>{{ (parseFloat(member.relativeImpactPercent ?? "0").toFixed(2)) }}%</div>
                </div>
            </div>

            <div v-if="showFooter" class="flex items-center gap-4 justify-between p-1 px-3 rounded-2xl">
                <div v-if="hiddenMembersCount" class="flex items-center gap-4">
                    <span class="w-4 text-right">+{{ hiddenMembersCount }}</span>
                    <div class="w-6 h-auto"></div>
                    <div class="text-black/50">Others</div>
                </div>
                <div v-else></div>
                <div class="flex">
                    <button class="text-purple cursor-pointer" @click="leaderboardSidebarVisible = true">See Leaderboard</button>
                </div>
            </div>
        </div>
    </div>
</template>